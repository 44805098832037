import React, { Component } from "react"
import Landing from "./landing"
import "../style/v1/common.scss"
import "../style/v1/header.scss"
import "../style/v1/landing.scss"
import "../style/v1/terms.scss"
import "../style/v1/footer.scss"
import "../style/v1/pricing.scss"
//import "@fontsource/rubik/300.css" // Weight 300.
//import SEO from "../pages/components/seo"

class Home extends Component {
  state = {}
  render() {
    return (
      <>
        {/* <SEO title="Online Resume/CV Builder" /> */}
        <Landing />
      </>
    )
  }
}
export default Home
